import React from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

interface RadioButtonType extends UseRadioProps {
  children?: React.ReactNode;
}

/**
 * For usage, refer to the following link.
 * https://chakra-ui.com/docs/components/radio#custom-radio-buttons
 */
const RadioButton: React.FC<RadioButtonType> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" fontSize="13px" w={0} h="100%" wordBreak="keep-all">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        cursor="pointer"
        borderWidth="1px"
        bgColor="white"
        h="100%"
        px={3}
        _checked={{
          bg: 'primary.100',
          color: 'primary.500',
          borderColor: 'primary.500'
        }}
        _focus={{
          boxShadow: 'none'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RadioButton;
