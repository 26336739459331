import React, { useEffect, useState } from 'react';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  Flex
} from '@chakra-ui/react';
import QRCode from 'react-qr-code';

import { getUser } from 'api';
import useHttpRequest from 'hooks/useHttpRequest';
import { UserInfoResponseType } from 'types/UserType';

import Loader from 'components/Loader';

import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';

interface QrCodeModalType {
  isOpen: boolean;
  onClose: () => void;
}

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: UserInfoResponseType;
};
const QrCodeModal: React.FC<QrCodeModalType> = ({ isOpen, onClose }) => {
  const [memberId, setMemberId] = useState<string>();
  const { data, isLoading, refetch }: HttpRequestType = useHttpRequest(getUser);

  // モーダルを開く時点に再度会員情報取得 + サービスメッセージ用のトークン送信
  useEffect(() => {
    if (isOpen) refetch(true);
    if (!isLoading) {
      // 会員番号 - 最初と最後の文字を外して、4桁ごとにスペースを追加
      const id = data.uid
        .slice(1, -1)
        .match(/.{1,4}/g)
        ?.join(' ');
      setMemberId(id);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="92%">
        <ModalCloseButton />
        <ModalHeader textAlign="center">会員証</ModalHeader>
        <ModalBody pb="20px">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Flex justifyContent="center" p="18px">
                <Flex
                  justifyContent="center"
                  bgColor="white"
                  border="12px solid #C1272D"
                  borderRadius="6px"
                  p="12px"
                >
                  <QRCode value={data.uid} viewBox={'0 0 85 85'} size={85} />
                </Flex>
              </Flex>
              <Text textAlign="center" mt="10px" mb="24px">
                <Text as="span">会員番号</Text>
                <Text as="span" ms="8px">
                  {/* 会員番号から最初と最後の文字を消す */}
                  {memberId}
                </Text>
              </Text>
              <HStack px="24px">
                <SunIcon width="22%" />
                <Text fontSize="lg">
                  上記コードを読み取れるように画面を明るくしましょう！
                </Text>
              </HStack>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default QrCodeModal;
