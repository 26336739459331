import { extendTheme } from '@chakra-ui/react';

import Accordion from './component/Accordion';
import Badge from './component/Badge';
import Button from './component/Button';
import Tabs from './component/Tab';
import Table from './component/Table';

export const theme = extendTheme({
  styles: {
    global: {
      html: {
        height: '100%',
        scrollPaddingTop: '4rem',
        overflow: 'hidden',
        overscrollBehavior: 'none'
      },
      body: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        color: 'dark.500',
        fontSize: '14px',
        backgroundColor: 'dark.100',
        WebkitTapHighlightColor: 'transparent'
      }
    }
  },
  fonts: {
    body: 'Noto Sans JP, system-ui, sans-serif'
  },
  fontSizes: {
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '34px',
    '6xl': '40px'
  },
  colors: {
    primary: {
      50: '#FEE9E7',
      100: '#F5BEB8',
      200: '#F19C9B',
      300: '#ED7676',
      400: '#E75956',
      500: '#C1272D',
      600: '#9E232A',
      700: '#722A33'
    },
    dark: {
      100: '#F2F2F2',
      200: '#D9D9D9',
      300: '#C2C2C2',
      400: '#727272',
      500: '#4D4845',
      600: '#231917'
    }
  },
  components: { Accordion, Badge, Button, Tabs, Table }
});
