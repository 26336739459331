import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Image, Text, useDisclosure } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { CouponDetailResponseType } from 'types/CouponType';
import useHttpRequest from 'hooks/useHttpRequest';
import { getCouponDetails } from 'api';

import FullHeightLayout from 'components/Layout/FullHeightLayout';
import StringToJsx from 'components/StringToJsx';
import QrCodeModal from './CouponDetailQrCodeModal';
import {
  ButtonActivate,
  ButtonAvail,
  ButtonOpenQr
} from './CouponDetailActionButton';
import Loader from 'components/Loader';

import LogoImage from '../../../assets/img/logo.svg';

// dayjs config
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: CouponDetailResponseType;
};

const CouponDetail: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading }: HttpRequestType = useHttpRequest(
    getCouponDetails,
    id
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  // クーポンを選択する
  const handleAvail = () =>
    navigate('activate', {
      state: {
        id: id,
        title: data.title,
        description: data.description,
        caution: data.caution,
        image_file_name: data.image_file_name,
        stores_name: data.stores_name,
        start_at: dayjs(data.start_at).format('YYYY年MM月DD日'),
        end_at: dayjs(data.end_at).format('YYYY年MM月DD日')
      }
    });

  let isAvailable, isActive, date, remaining;
  if (!isLoading && data) {
    const currDate = dayjs();

    // クーポン期間確認
    isAvailable =
      currDate.isSameOrAfter(data.start_at, 'day') &&
      currDate.isSameOrBefore(data.end_at, 'day');

    isActive =
      data.expire_at !== null && currDate.isSameOrBefore(data.expire_at);

    date =
      dayjs(data.start_at).format('YYYY年MM月DD日') +
      ' ~ ' +
      dayjs(data.end_at).format('YYYY年MM月DD日');

    // クーポン利用回数
    remaining = data.max_use_times - data.use_count;
  }

  if (isLoading) {
    return (
      <FullHeightLayout title="クーポン詳細" bgColor="white" mt="-24px">
        <Loader />
      </FullHeightLayout>
    );
  }

  if (!data) {
    return (
      <FullHeightLayout title="クーポン詳細" bgColor="white" mt="-24px">
        <Text color="dark.500" mt="3rem" textAlign="center">
          対象のクーポンは見つかりませんでした
        </Text>
      </FullHeightLayout>
    );
  }

  return (
    <FullHeightLayout title="クーポン詳細" bgColor="white" mt="-24px">
      <Box mx="-16px">
        <Image
          src={data.image_file_name}
          fallbackSrc={LogoImage}
          p={data.image_file_name ? '' : '36px'}
          w="100%"
        />
      </Box>
      <Box mt="28px">
        <Text color="dark.500">{data.stores_name.join(' / ')}</Text>
        <Text fontSize="lg" fontWeight="medium" my="8px">
          {data.title}
        </Text>
        <Text color="dark.400">＜有効期限＞</Text>
        <Text color="dark.400">{date}</Text>
        {isActive ? (
          <>
            {/* NOTE: In case of already used coupon, fixed value - isUsed */}
            <ButtonActivate isUsed={true} />
            <ButtonOpenQr onOpen={onOpen} />
          </>
        ) : (
          <ButtonAvail
            remaining={remaining || 0}
            isDisabled={!isAvailable}
            handleAvail={handleAvail}
          />
        )}
        <Box mb="24px">
          <StringToJsx text={data.description} />
        </Box>
        {!isActive && data.caution && (
          <Box>
            <Text fontWeight="medium" mb="8px">
              注意事項
            </Text>
            <StringToJsx text={data.caution} />
          </Box>
        )}
      </Box>
      <QrCodeModal isOpen={isOpen} onClose={onClose} />
    </FullHeightLayout>
  );
};
export default CouponDetail;
