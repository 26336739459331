import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { HStack, VStack, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// dayjs config
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

import { CouponListResponseType } from 'types/CouponType';
import useHttpRequest from 'hooks/useHttpRequest';
import { getCouponList } from 'api';

import CouponCard from 'components/Card/CouponCard';
import Loader from 'components/Loader';

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: CouponListResponseType;
};

const RecommendedCoupon: React.FC = () => {
  const { data, isLoading }: HttpRequestType = useHttpRequest(getCouponList);

  const currDate = dayjs();

  return (
    <VStack w="100%" py="16px">
      <HStack w="100%" justifyContent="space-between" px="8px">
        <Text fontWeight="medium">お得なクーポン(新着順)</Text>
        <RouteLink to="/coupon">
          <Text display="flex" alignItems="center" color="primary.500">
            一覧で見る
            <ChevronRightIcon fontSize="2xl" />
          </Text>
        </RouteLink>
      </HStack>
      {isLoading ? (
        <Loader />
      ) : (
        <HStack w="100%" overflowX="scroll" alignItems="stretch">
          {!data.coupons.length && (
            <Text mt="8px" ms="12px">
              クーポンがありません。
            </Text>
          )}
          {data.coupons.map((coupon, ind) => (
            <CouponCard
              link={`/coupon/${coupon.id}`}
              title={coupon.title}
              date={
                dayjs(coupon.start_at).format('YYYY年MM月DD日') +
                ' ~ ' +
                dayjs(coupon.end_at).format('YYYY年MM月DD日')
              }
              store={coupon.stores_name}
              image={coupon.image_file_name}
              isUsed={
                coupon.expire_at !== null &&
                currDate.isSameOrBefore(coupon.expire_at)
              }
              width={data.coupons.length > 1 ? '75vw' : '100%'}
              height="100%"
              key={`cp-${ind}`}
            />
          ))}
        </HStack>
      )}
    </VStack>
  );
};

export default RecommendedCoupon;
