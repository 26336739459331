import BronzeStamp from '../../../assets/img/stamp_br.png';
import SilverStamp from '../../../assets/img/stamp_sv.png';
import GoldStamp from '../../../assets/img/stamp_gd.png';

const tierConfig = [
  {
    name: 'bronze',
    bg: 'linear-gradient(144deg, rgba(227,161,96,1) 0%, rgba(226,174,127,1) 34%, rgba(224,150,83,1) 72%, rgba(227,161,96,1) 100%)',
    stampPerPage: 20,
    stampimage: BronzeStamp
  },
  {
    name: 'silver',
    bg: 'linear-gradient(144deg, rgba(164,169,176,1) 0%, rgba(213,215,218,1) 34%, rgba(164,169,176,1) 72%, rgba(213,215,218,1) 100%)',
    stampPerPage: 50,
    stampimage: SilverStamp
  },
  {
    name: 'gold',
    bg: 'linear-gradient(144deg, rgba(235,194,83,1) 0%, rgba(255,230,162,1) 34%, rgba(235,194,83,1) 72%, rgba(255,230,162,1) 100%)',
    stampPerPage: 100,
    stampimage: GoldStamp
  }
];
export default tierConfig;
