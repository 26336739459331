import React from 'react';

import { ReactComponent as CouponIcon } from 'assets/icons/coupon.svg';
import { ReactComponent as StampIcon } from 'assets/icons/stamp.svg';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as StoreIcon } from 'assets/icons/store.svg';
import { ReactComponent as QRIcon } from 'assets/icons/qr.svg';

/** ICONS */
const Coupon: React.ReactNode = <CouponIcon width="100%" height="100%" />;
const Stamp: React.ReactNode = <StampIcon width="100%" height="100%" />;
const Bell: React.ReactNode = <BellIcon width="100%" height="100%" />;
const Store: React.ReactNode = <StoreIcon width="100%" height="100%" />;
const QR: React.ReactNode = <QRIcon width="100%" height="100%" />;

const links = [
  {
    label: 'クーポン',
    icon: Coupon,
    link: '/coupon'
  },
  {
    label: 'スタンプ',
    icon: Stamp,
    link: '/stamp'
  },
  {
    label: '会員証',
    icon: QR,
    link: '/'
  },
  {
    label: 'お知らせ',
    icon: Bell,
    link: '/news'
  },
  {
    label: '店舗情報',
    icon: Store,
    link: '/store'
  }
] as const;

export default links;
