import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

const Tabs: ComponentMultiStyleConfig = {
  parts: ['tablist', 'tab'],
  variants: {
    pill: {
      tablist: {
        bgColor: 'white',
        border: '1px solid #C2C2C2',
        borderRadius: '8px',
        WebkitTapHighlightColor: 'transparent'
      },
      tab: {
        color: 'dark.400',
        _selected: {
          color: 'white',
          bgColor: 'primary.500',
          border: 'none'
        },
        _active: {
          color: 'white',
          boxShadow: 'none'
        },
        _focus: {
          color: 'white',
          boxShadow: 'none'
        }
      }
    }
  },
  defaultProps: {
    variant: 'pill'
  }
};

export default Tabs;
