import React from 'react';
import { Flex, Text, createStandaloneToast, ToastId } from '@chakra-ui/react';

const Toast = (text: string): ToastId | undefined => {
  const { toast } = createStandaloneToast();

  return toast({
    position: 'top',
    duration: 1000,
    containerStyle: {
      width: '95%'
    },
    render: (): React.ReactNode => (
      <Flex
        py="12px"
        justifyContent="center"
        backgroundColor="#333333E5"
        borderRadius="4px"
      >
        <Text color="white" m="0">
          {text}
        </Text>
      </Flex>
    )
  });
};
export default Toast;
