import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import MainLayout from 'components/Layout/MainLayout';
import MyStoreNews from './MyStoreNews';
import AllStoreNews from './AllStoreNews';

const NewsList: React.FC = () => {
  return (
    <MainLayout title="お知らせ">
      <Tabs isLazy>
        <TabList mb="16px">
          <Tab w="50%" borderRadius="8px 0 0 8px" py="12px">
            マイ店舗
          </Tab>
          <Tab w="50%" borderRadius="0 8px 8px 0" py="12px">
            全店舗
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0">
            <MyStoreNews />
          </TabPanel>
          <TabPanel p="0">
            <AllStoreNews />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MainLayout>
  );
};
export default NewsList;
