import React from 'react';
import liff from '@line/liff';
import { Box, Heading, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import FullHeightLayout from 'components/Layout/FullHeightLayout';
import { FramerButton } from 'libs/chakra/components';

type ErrorContentType = {
  [key: string]: {
    heading: string;
    text: string;
  };
};

const CommonError: React.FC = () => {
  const paramStr = window.location.search;
  const searchParams = new URLSearchParams(paramStr);
  const error_code = searchParams.get('error_code');

  const errorContent: ErrorContentType = {
    'create-user-failure': {
      heading: 'ユーザーの登録処理に失敗しました。',
      text: 'アプリを再起動してください。'
    },
    'server-error': {
      heading: 'サーバーエラー',
      text: 'サーバー側でエラーが発生しました。アプリを再起動してください。'
    },
    'session-expired': {
      heading: 'セッションの有効期限切れ',
      text: 'セッションの有効期限が切れています。アプリを再起動してください。'
    }
  };

  const cleanUp = () => {
    // NOTE:
    // 「401&&"IdToken expired."」の際、ログインができなくなる事象を
    // 回避するためにhttp.interceptorsから切り出した処理
    error_code === 'session-expired' && localStorage.clear();

    liff.closeWindow();
  };

  return (
    <FullHeightLayout title="エラー" showFooter={false} justifyContent="center">
      <Box
        display="flex"
        flexDir="column"
        px="2.5rem"
        alignItems="center"
        textAlign="center"
      >
        <Box
          rounded="full"
          border="1px solid"
          p="1rem"
          borderColor="red.500"
          mb="1rem"
        >
          <CloseIcon w={8} h={8} color="red.500" />
        </Box>
        <Heading mb="1rem" color="blackAlpha.900">
          {error_code ? errorContent[error_code].heading : 'エラー'}
        </Heading>
        <Text mb="3rem" color="blackAlpha.900" fontSize="lg">
          {error_code
            ? errorContent[error_code].text
            : 'エラーが発生しました。アプリを再起動してください。'}
        </Text>

        <Box width="100%">
          <FramerButton
            whileTap={{ scale: 0.95, boxShadow: 'rgba(99, 99, 99, 0.2) 0' }}
          >
            <Text
              onClick={cleanUp}
              fontSize="lg"
              width="100%"
              px="1rem"
              py="0.75rem"
            >
              アプリを閉じる
            </Text>
          </FramerButton>
        </Box>
      </Box>
    </FullHeightLayout>
  );
};
export default CommonError;
