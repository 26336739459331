import React, { useEffect } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { CouponListResponseType } from 'types/CouponType';
import useHttpRequest from 'hooks/useHttpRequest';
import { getCouponList, readCoupon } from 'api';

import MainLayout from 'components/Layout/MainLayout';
import CouponCard from 'components/Card/CouponCard';
import Loader from 'components/Loader';

// dayjs config
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: CouponListResponseType;
};

const CouponList: React.FC = () => {
  const { data, isLoading }: HttpRequestType = useHttpRequest(getCouponList);

  const currDate = dayjs();

  useEffect(() => {
    readCoupon();
  }, []);

  return (
    <MainLayout title="クーポン">
      <VStack spacing="12px">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!data.coupons.length && <Text>クーポンがありません。</Text>}
            {data.coupons.map((coupon, ind) => (
              <CouponCard
                link={`/coupon/${coupon.id}`}
                title={coupon.title}
                date={
                  dayjs(coupon.start_at).format('YYYY年MM月DD日') +
                  ' ~ ' +
                  dayjs(coupon.end_at).format('YYYY年MM月DD日')
                }
                store={coupon.stores_name}
                image={coupon.image_file_name}
                isUsed={
                  coupon.expire_at !== null &&
                  currDate.isSameOrBefore(coupon.expire_at)
                }
                width="100%"
                key={`cp-${ind}`}
              />
            ))}
          </>
        )}
      </VStack>
    </MainLayout>
  );
};
export default CouponList;
