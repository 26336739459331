import React from 'react';
import { Grid, GridItem, useRadioGroup } from '@chakra-ui/react';

import useHttpRequest from 'hooks/useHttpRequest';
import { getBrandList } from 'api';
import { BrandListType } from 'types/StoreType';

import RadioButton from 'components/RadioButton';
import Loader from 'components/Loader';

const BrandSelect: React.FC<{ setBrand: (id: string) => void }> = ({
  setBrand
}) => {
  const { data, isLoading }: { data: BrandListType; isLoading: boolean } =
    useHttpRequest(getBrandList);

  // 店舗IDの配列を用意
  let values;
  if (!isLoading) {
    values = data.brands.map((brand) => brand.id.toString());
    values = ['', ...values];
  }

  const handleChange = (id: string) => setBrand(id);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'brand',
    defaultValue: '',
    onChange: handleChange
  });

  const group = getRootProps();

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={0} mx="-16px" {...group}>
      {isLoading ? (
        <Loader />
      ) : (
        values?.map((value) => {
          const radio = getRadioProps({ value });
          const brand = data.brands.filter(
            (brand) => brand.id.toString() == value
          );
          return (
            <GridItem w="100%" h="50px" key={value}>
              <RadioButton {...radio}>
                {value === '' ? 'すべて' : brand[0].name}
              </RadioButton>
            </GridItem>
          );
        })
      )}
    </Grid>
  );
};
export default BrandSelect;
