import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { UnorderedList } from '@chakra-ui/react';

import { ZINDEX } from 'utils/constants';

import FooterItem from './FooterItem';
import links from './links';

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  const isCurrentPath = (targetPath: string, currentPath: string) =>
    matchPath(targetPath, currentPath) ? true : false;

  return (
    <UnorderedList
      display="flex"
      justifyContent="space-around"
      boxShadow="0px -2px 10px #E2E2E2"
      bgColor="white"
      position="fixed"
      bottom="0"
      zIndex={ZINDEX.footer}
      w="100%"
      m={0}
    >
      {links.map((item, ind) => (
        <FooterItem
          {...item}
          isActive={isCurrentPath(`${item.link}/*`, pathname)}
          key={`ft-${ind}`}
        />
      ))}
    </UnorderedList>
  );
};
export default Footer;
