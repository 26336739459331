import React, { useEffect } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import {
  VStack,
  Text,
  Box,
  UnorderedList,
  ListItem,
  HStack,
  Icon,
  useDisclosure
} from '@chakra-ui/react';

import { UserInfoResponseType } from 'types/UserType';
import { getUser } from 'api';
import useHttpRequest from 'hooks/useHttpRequest';

import MainLayout from 'components/Layout/MainLayout';
import Loader from 'components/Loader';
import CouponNoticeModal from 'components/CouponNoticeModal';
import StampList from './StampList';

import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: UserInfoResponseType;
};

const Stamp: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading }: HttpRequestType = useHttpRequest(getUser);

  useEffect(() => {
    if (!isLoading) {
      // クーポン獲得モーダル
      if (data.is_unread_coupon_exist) onOpen();
    }
  }, [isLoading]);

  return (
    <MainLayout title="スタンプ">
      {isLoading ? (
        <Loader />
      ) : (
        <VStack spacing="16px">
          <Box borderRadius="8px" bgColor="white" px="16px" py="12px" w="100%">
            <Text fontSize="lg" fontWeight="medium">
              期間累計お支払い金額800円ごとに1スタンプ獲得
            </Text>
            <RouteLink to="about">
              <HStack spacing="4px" alignItems="center" justifyContent="end">
                <Icon stroke="primary.500" w="1rem" h="1rem">
                  <QuestionIcon />
                </Icon>
                <Text color="primary.500" textDecor="underline">
                  特典について
                </Text>
              </HStack>
            </RouteLink>
          </Box>
          {data.next_coupons && (
            <VStack
              spacing="0"
              filter="drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.05))"
              w="100%"
            >
              <Text
                borderRadius="8px 8px 0px 0px"
                bgColor="primary.500"
                fontSize="lg"
                fontWeight="medium"
                color="white"
                py="12px"
                px="16px"
                w="100%"
              >
                あと{data.next_coupons_get_in}スタンプで
              </Text>
              <Box
                borderRadius="0px 0px 8px 8px"
                bgColor="white"
                px="42px"
                py="16px"
                w="100%"
              >
                <UnorderedList fontSize="lg" fontWeight="medium" m={0}>
                  {data.next_coupons.map((item, ind) => (
                    <ListItem key={`title-${ind}`}>{item.title}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </VStack>
          )}
          <StampList
            tier={data.stamp_card_color}
            totalStamps={data.total_stamp_count}
            currStamps={data.stamp_count_in_current_card}
            pageNum={data.stamp_card_count}
          />
        </VStack>
      )}
      <CouponNoticeModal isOpen={isOpen} onClose={onClose} />
    </MainLayout>
  );
};
export default Stamp;
