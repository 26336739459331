import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Box, Image, ListItem, Text, List } from '@chakra-ui/react';

import { NewsDetailResponseType } from 'types/NewsType';
import useHttpRequest from 'hooks/useHttpRequest';
import { getNewsDetail } from 'api';

import FullHeightLayout from 'components/Layout/FullHeightLayout';
import Loader from 'components/Loader';
import StringToJsx from 'components/StringToJsx';

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: NewsDetailResponseType;
};

const NewsDetail: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading }: HttpRequestType = useHttpRequest(
    getNewsDetail,
    id
  );

  if (isLoading) {
    return (
      <FullHeightLayout title="お知らせ" bgColor="white" mt="-24px">
        <Loader />
      </FullHeightLayout>
    );
  }

  if (!data) {
    return (
      <FullHeightLayout title="お知らせ" bgColor="white" mt="-24px">
        <Text color="dark.500" mt="3rem" textAlign="center">
          対象のお知らせは見つかりませんでした
        </Text>
      </FullHeightLayout>
    );
  }

  return (
    <FullHeightLayout title="お知らせ" bgColor="white" mt="-24px">
      <Box mx="-16px">
        <Image src={data.image_file_name} w="100%" />
      </Box>
      <Box mt="28px">
        <Text fontSize="xl" fontWeight="medium" my="8px">
          {data.title}
        </Text>
        <Text color="dark.400" mb="10px">
          {dayjs(data.published_at).format('YYYY年MM月DD日')}
        </Text>
        <Box mb="16px">
          <StringToJsx fontSize="lg" text={data.description} />
        </Box>
        <Box color="dark.400">
          <Text mb="6px">対象店舗：</Text>
          <List>
            {data.stores_name.length > 0 ? (
              data.stores_name.map((store, ind) => (
                <ListItem key={`st-${ind}`}>{store}</ListItem>
              ))
            ) : (
              <ListItem>全店共通</ListItem>
            )}
          </List>
        </Box>
      </Box>
    </FullHeightLayout>
  );
};
export default NewsDetail;
