import React from 'react';
import { Flex, Container, ContainerProps } from '@chakra-ui/react';

import usePageTitle from 'hooks/usePageTitle';

import Footer from 'components/Footer';

interface FullHeightLayoutType extends ContainerProps {
  title?: string;
  children?: React.ReactNode;
  showFooter?: boolean;
}

const FullHeightLayout: React.FC<FullHeightLayoutType> = ({
  title,
  showFooter = true,
  children,
  ...props
}) => {
  usePageTitle(title || '銚子丸');

  return (
    <Flex direction="column" minHeight="100vh" py="24px">
      <Container
        display="flex"
        flexDirection="column"
        width={['100%', 480]}
        flex="1"
        mb="2.8rem"
        pb="2.8rem"
        {...props}
      >
        {children}
      </Container>
      {showFooter && <Footer />}
    </Flex>
  );
};

export default FullHeightLayout;
