import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { isValidMotionProp, motion } from 'framer-motion';

// https://www.framer.com/docs/lazy-motion/
export const FramerButton = chakra(motion.button, {
  baseStyle: {
    width: '100%',
    rounded: 'full',
    bgGradient: 'linear-gradient(to-r, #E71A0F, #C90000)',
    color: 'white',
    fontWeight: '700',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
  },
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop)
});

export const FramerBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop)
});

export const FramerLink = chakra(motion.a, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop)
});
