import { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';

interface ReturnType {
  data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isLoading: boolean;
  error: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  refetch: (arg?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const useHttpRequest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (args: any) => Promise<AxiosResponse<any, any>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg?: any
): ReturnType => {
  const [isLoading, setIsLoading] = useState<ReturnType['isLoading']>(true);
  const [error, setError] = useState<ReturnType['error']>();
  const [data, setData] = useState<ReturnType['data']>();

  const execOnce = useRef(false); // リクエストを一回実行させる
  useEffect(() => {
    if (!execOnce.current) {
      fn(arg)
        .then((res) => {
          // 成功
          setData(res.data.data);
        })
        .catch((err) => {
          // 失敗
          setError(err.response);
        })
        .finally(() => setIsLoading(false));
    }
    return () => {
      execOnce.current = true;
    };
  }, [arg]);

  // TODO: optimize code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refetch = (arg?: any) => {
    setIsLoading(true);
    fn(arg)
      .then((res) => {
        // 成功
        setData(res.data.data);
      })
      .catch((err) => {
        // 失敗
        setError(err.response);
      })
      .finally(() => setIsLoading(false));
  };

  return { data, error, isLoading, refetch };
};

export default useHttpRequest;
