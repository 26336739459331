import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import GoogleAnalytics from 'utils/GoogleAnalytics';

import Top from 'pages/Top';
import CouponList from 'pages/Coupon/CouponList';
import CouponDetail from 'pages/Coupon/CouponDetail';
import Stamp from 'pages/Stamp';
import About from 'pages/Stamp/StampAbout';
import NewsList from 'pages/News/NewsList';
import NewsDetail from 'pages/News/NewsDetail';
import Store from 'pages/Store';
import CouponDetailConfirm from 'pages/Coupon/CouponDetail/CouponDetailConfirm';
import Contact from 'pages/Contact';
import ScrollToTop from 'components/ScrollTop';
import CommonError from 'pages/Error/CommonError';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/coupon" element={<CouponList />} />
          <Route path="/coupon/:id" element={<CouponDetail />} />
          <Route
            path="/coupon/:id/activate"
            element={<CouponDetailConfirm />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/stamp" element={<Stamp />} />
          <Route path="/stamp/about" element={<About />} />
          <Route path="/news" element={<NewsList />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/store" element={<Store />} />
          <Route
            path="/error/error_code=:error_code"
            element={<CommonError />}
          />
        </Routes>
      </ScrollToTop>
      <GoogleAnalytics />
    </BrowserRouter>
  );
};
export default AppRoutes;
