import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  Badge,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  useToast
} from '@chakra-ui/react';

import { AllStoreListType, StoreInfoType } from 'types/StoreType';
import { addMyStore, deleteMyStore, getAllStoreList } from 'api';
import useHttpRequest from 'hooks/useHttpRequest';

import StoreCard from 'components/Card/StoreCard';
import Toast from 'components/Toast';
import ConfirmModal from './StoreDeleteModal';
import Loader from 'components/Loader';

import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';

interface AreaSearchType {
  brand?: string;
  myStore: string[];
  setMyStore: React.Dispatch<React.SetStateAction<string[]>>;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AreaChildAccordionType {
  areas: {
    id: number;
    name: string;
    parent_id: number;
    stores: StoreInfoType[];
  }[];
  myStore: string[];
  parentIndex: number;
  handleConfirmDelete: (storeId: string, name: string) => void;
  handleAdd: (storeId: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: AllStoreListType;
};

const NumberBadge: React.FC<{ num: number }> = ({ num }) => {
  return (
    <Badge
      backgroundColor="dark.400"
      color="white"
      borderRadius="25px"
      lineHeight={1}
      fontSize="md"
      me="8px"
      p="5px 8px"
    >
      {num}
    </Badge>
  );
};

const AreaChildAccordion: React.FC<AreaChildAccordionType> = ({
  areas,
  myStore,
  parentIndex,
  handleConfirmDelete,
  handleAdd
}) => {
  return (
    <Accordion w="100%" bgColor="dark.100" defaultIndex={[]} allowMultiple>
      {areas.map(
        (child, ind) =>
          child.stores.length > 0 && (
            <AccordionItem w="100%" key={`child-${ind}`}>
              <AccordionButton
                bgColor="dark.100"
                _hover={{ bgColor: 'dark.100' }}
                _focus={{ bgColor: 'dark.100' }}
                _active={{ bgColor: 'dark.100' }}
              >
                <HStack
                  flex={1}
                  py="5px"
                  w="100%"
                  id={`child-${parentIndex}-${ind}`}
                >
                  <Text ps="24px" fontSize="lg">
                    {child.name}
                  </Text>
                </HStack>
                <Box>
                  <NumberBadge num={child.stores.length} />
                  <AccordionIcon />
                </Box>
              </AccordionButton>
              <AccordionPanel bgColor="dark.100" py="20px">
                <VStack spacing="16px">
                  {child.stores.map((store: StoreInfoType, ind) => (
                    <StoreCard
                      id={store.id}
                      name={store.name}
                      address={store.postal_code + ' ' + store.address}
                      tel={store.tel}
                      link={store.url}
                      mapLink={store.google_map_url}
                      isFavorite={myStore.includes(store.id.toString())}
                      handleDelete={() =>
                        handleConfirmDelete(store.id.toString(), store.name)
                      }
                      handleAdd={handleAdd}
                      key={`st-${ind}`}
                    />
                  ))}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          )
      )}
    </Accordion>
  );
};

const AreaSearch: React.FC<AreaSearchType> = ({
  brand,
  myStore,
  setMyStore,
  setIsUpdated
}) => {
  const [selectedStoreId, setSelectedStoreId] = useState<string>();
  const [selectedStore, setselectedStore] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { data, isLoading, refetch }: HttpRequestType = useHttpRequest(
    getAllStoreList,
    brand
  );

  const handleAdd = (
    storeId: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const buttonEl = e.currentTarget;
    buttonEl.disabled = true;
    addMyStore({ store_id: parseInt(storeId) })
      .then(() => {
        // 成功
        setMyStore([...myStore, storeId]);
        Toast('マイ店舗で登録完了しました');
        setIsUpdated(true);
      })
      .catch((err) =>
        // 失敗
        toast({
          title: err.response.data.errors[0],
          position: 'top',
          status: 'error',
          isClosable: true
        })
      )
      .finally(() => {
        buttonEl.disabled = false;
      });
  };

  const handleConfirmDelete = (storeId: string, name: string) => {
    onOpen();
    setSelectedStoreId(storeId);
    setselectedStore(name);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    // NOTE: "削除" button in the confirmation modal
    const buttonEl = e.currentTarget;
    if (selectedStoreId) {
      buttonEl.disabled = true;
      deleteMyStore(selectedStoreId.toString())
        .then(() => {
          // 成功
          const newList = myStore.filter((id) => id !== selectedStoreId);
          setMyStore(newList);
          setIsUpdated(true);
        })
        .catch((err) => {
          // 失敗
          toast({
            title: err.response.data.errors[0],
            position: 'top',
            status: 'error',
            isClosable: true
          });
        })
        .finally(() => {
          buttonEl.disabled = false;
        });
    }
    onClose();
  };

  // 選択されているブランドが変わった場合、refetch
  useEffect(() => refetch(brand), [brand]);

  return (
    <Box width="100%">
      <VStack spacing={0} mx="-16px">
        {isLoading ? (
          <Loader />
        ) : (
          <Accordion w="100%" defaultIndex={[]} allowMultiple>
            {data.parents.map(
              (area, ind) =>
                area.child_areas.flatMap((child) => child.stores).length >
                  0 && (
                  <AccordionItem key={`parent-${ind}`}>
                    <AccordionButton>
                      <HStack flex={1} py="5px" w="100%" id={`parent-${ind}`}>
                        <LocationIcon />
                        <Text fontSize="lg">{area.name}</Text>
                      </HStack>
                      <Box>
                        <NumberBadge
                          num={area.child_areas.flatMap((x) => x.stores).length}
                        />
                        <AccordionIcon />
                      </Box>
                    </AccordionButton>
                    <AccordionPanel bgColor="dark.100" p={0}>
                      <AreaChildAccordion
                        parentIndex={ind}
                        areas={area.child_areas}
                        myStore={myStore}
                        handleAdd={handleAdd}
                        handleConfirmDelete={handleConfirmDelete}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                )
            )}
          </Accordion>
        )}
        <ConfirmModal
          title={selectedStore || ''}
          isOpen={isOpen}
          onClose={onClose}
          handleRemove={handleDelete}
        />
      </VStack>
    </Box>
  );
};
export default AreaSearch;
