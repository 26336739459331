import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '5px',
    py: '4px',
    px: '8px',
    fontWeight: 'medium',
    fontSize: 'md',
    _disabled: {
      bgColor: 'dark.300',
      color: 'dark.400',
      pointerEvents: 'none'
    },
    _hover: {
      _disabled: {
        bgColor: 'dark.300',
        color: 'dark.400'
      }
    }
  },
  variants: {
    'primary-solid': {
      bgColor: 'primary.500',
      color: 'white'
    },
    'primary-ghost': {
      bgColor: 'primary.100',
      color: 'primary.600'
    },
    'primary-outline': {
      bgColor: 'white',
      color: 'primary.500',
      border: '1px solid #C1272D'
    },
    'secondary-solid': {
      bgColor: 'dark.600',
      color: 'white'
    },
    'secondary-outline': {
      bgColor: 'white',
      color: 'dark.500',
      border: '1px solid #C2C2C2'
    }
  }
};

export default Button;
