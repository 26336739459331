import React from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { Box, Image, Text, useDisclosure, useToast } from '@chakra-ui/react';

import { CouponDetailResponseType } from 'types/CouponType';
import { applyCoupon } from 'api';

import FullHeightLayout from 'components/Layout/FullHeightLayout';
import Toast from 'components/Toast';
import StringToJsx from 'components/StringToJsx';
import QrCodeModal from './CouponDetailQrCodeModal';
import { ButtonActivate, ButtonOpenQr } from './CouponDetailActionButton';

import LogoImage from '../../../assets/img/logo.svg';

const CouponDetailConfirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CouponDetailResponseType;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  // パス(/coupon/:id/activate)からクーポンIDを取得
  const { id: couponId } = useParams();

  // クーポンを利用する
  const handleActivate = (e: React.MouseEvent<HTMLButtonElement>) => {
    const buttonEl = e.currentTarget;
    buttonEl.disabled = true;
    buttonEl.innerText = '処理中...';
    applyCoupon({ coupon_id: state.id })
      .then(() => {
        Toast('クーポンを利用しました');
        navigate(-1);
      })
      .catch((err) => {
        buttonEl.disabled = false;
        buttonEl.innerText = 'この画面を店舗のスタッフに提示してください';
        // 失敗
        toast({
          title: err.response.data.errors[0],
          position: 'top',
          status: 'error',
          isClosable: true
        });
      });
  };

  if (!state) {
    return <Navigate to={`/coupon/${couponId}`} />;
  }

  return (
    <FullHeightLayout bgColor="white" mt="-24px">
      <Box mx="-16px">
        <Image
          src={state.image_file_name}
          fallbackSrc={LogoImage}
          p={state.image_file_name ? '' : '36px'}
          w="100%"
        />
      </Box>
      <Box mt="28px">
        <Text color="dark.500">{state.stores_name.join(' / ')}</Text>
        <Text fontSize="lg" fontWeight="medium" my="8px" wordBreak="break-all">
          {state.title}
        </Text>
        <Text color="dark.400">＜有効期限＞</Text>
        <Text color="dark.400">
          {state.start_at} ~ {state.end_at}
        </Text>
        <ButtonActivate isUsed={false} handleActivate={handleActivate} />
        <ButtonOpenQr onOpen={onOpen} />
      </Box>
      <Box mb="24px">
        <StringToJsx text={state.description} />
      </Box>
      <QrCodeModal isOpen={isOpen} onClose={onClose} />
    </FullHeightLayout>
  );
};
export default CouponDetailConfirm;
