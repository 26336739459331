import React, { useEffect, useState } from 'react';
import { Text, Box, Button, Flex } from '@chakra-ui/react';
import QRCode from 'react-qr-code';

import { ZINDEX } from 'utils/constants';
import useHttpRequest from 'hooks/useHttpRequest';

type QrCodeType = Omit<
  ReturnType<typeof useHttpRequest>,
  'data' | 'isLoading' | 'error'
> & {
  uid: string;
};

const QrCode: React.FC<QrCodeType> = ({ uid, refetch }) => {
  // ぼやかしはアプリ立ち上げにつき1回
  const isUnblurred = sessionStorage.getItem('blur') === 'true';
  const [blur, setBlur] = useState<boolean>(!isUnblurred);
  const [memberId, setMemberId] = useState<string>();

  const imageBlur = blur ? 'blur(4px)' : 'blur(0px)';

  useEffect(() => {
    // 会員番号 - 最初と最後の文字を外して、4桁ごとにスペースを追加
    const id = uid
      .slice(1, -1)
      .match(/.{1,4}/g)
      ?.join(' ');
    setMemberId(id);
  }, []);

  return (
    <>
      <Box position="relative">
        {blur && (
          <Button
            variant="primary-outline"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={ZINDEX.qrBlurButton}
            m="auto"
            h="48px"
            w="100%"
            onClick={() => {
              setBlur(false);
              refetch(true); // 再度会員情報取得 + サービスメッセージ用のトークン送信
              sessionStorage.setItem('blur', 'true');
            }}
          >
            スタンプ/クーポン情報を取得する
            <br />
            (画面タップして会員証を表示)
          </Button>
        )}
        <Flex
          filter={imageBlur}
          justifyContent="center"
          transition="ease-out .7s"
          p="8px"
        >
          <QRCode value={uid} size={85} viewBox={'0 0 85 85'} />
        </Flex>
      </Box>
      {!blur && (
        <Text textAlign="center" mt="16px">
          <Text as="span">会員番号</Text>
          <Text as="span" ms="8px">
            {/* 会員番号から最初と最後の文字を消す */}
            {memberId}
          </Text>
        </Text>
      )}
    </>
  );
};

export default QrCode;
