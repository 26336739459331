import React, { useEffect, useState } from 'react';
import {
  Box,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList
} from '@chakra-ui/react';

import { getCouponInfo } from 'api';
import { CouponInfoResponseType } from 'types/CouponType';

import FullHeightLayout from 'components/Layout/FullHeightLayout';

const About: React.FC = () => {
  const [couponInfo, setCouponInfo] = useState<CouponInfoResponseType[]>();

  useEffect(() => {
    getCouponInfo().then((res) => setCouponInfo(res.data.data.stamp_coupons));
  }, []);

  return (
    <FullHeightLayout title="特典について" bgColor="white" mt="-24px">
      <Box pt="64px" mb="36px">
        <Text fontSize="lg" fontWeight="medium" mb="8px">
          特典について
        </Text>
        <UnorderedList px="12px" lineHeight="26px">
          <ListItem>
            スタンプ集計期間内に貯めた累計スタンプ数に応じて特典のクーポンを発行いたします。
          </ListItem>
          <ListItem>
            会計時に会員証コードをレジで読み込んだ2日後以降にスタンプが反映されます。
          </ListItem>
          {/* TODO: 期間日付を動的に変更 */}
          <ListItem>スタンプの集計期間は2024年5月16日～2025年5月15日</ListItem>
          <ListItem>
            <Text as="span" color="primary.500">
              すし銚子丸、すし銚子丸&nbsp;雅、江戸前すし&nbsp;百萬石の3業態が対象となります。
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>
      <TableContainer>
        <Table size="sm" variant="custom">
          <Thead>
            <Tr>
              <Th>
                累計スタンプ
                <br />
                枚数
              </Th>
              <Th>
                特典① <br />
                (有効期限)
              </Th>
              <Th>
                特典② <br />
                (有効期限)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {couponInfo?.map((info, ind) => {
              const couponTitle = (
                coupon: CouponInfoResponseType['coupons'][number]
              ) => (
                <>
                  <Text as="span" whiteSpace="nowrap">
                    {coupon.title}
                  </Text>
                  <br />
                  <Text as="span">({coupon.available_days || 0}日間)</Text>
                </>
              );
              return (
                <Tr key={`tb-${ind}`}>
                  <Td>{info.available_stamp_count}枚</Td>
                  <Td>
                    {info.coupons[0] ? couponTitle(info.coupons[0]) : '-'}
                  </Td>
                  <Td>
                    {info.coupons[1] ? couponTitle(info.coupons[1]) : '-'}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </FullHeightLayout>
  );
};
export default About;
