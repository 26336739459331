import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  OrderedList,
  ListItem,
  HStack,
  useDisclosure,
  Link,
  Box
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import { openWindow } from 'utils/line';
import useHttpRequest from 'hooks/useHttpRequest';
import { getUser } from 'api';
import { UserInfoResponseType } from 'types/UserType';

import MainLayout from 'components/Layout/MainLayout';
import CouponNoticeModal from 'components/CouponNoticeModal';
import Loader from 'components/Loader';
import QrCode from './TopQrCode';
import RecommendedCoupon from './TopRecommendedCoupon';

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data: UserInfoResponseType;
};

const LinkBox: React.FC<{
  link: string;
  label: string;
  isRouterLink?: boolean;
}> = ({ link, label, isRouterLink }) => {
  return (
    <>
      {isRouterLink ? (
        <Link
          display="flex"
          justifyContent="space-between"
          py="6px"
          px="12px"
          w="100%"
          _hover={{ textDecoration: 'none' }}
          as={RouterLink}
          to="/contact"
        >
          <Text as="span" fontSize="lg" fontWeight="medium">
            {label}
          </Text>
          <ChevronRightIcon fontSize="2xl" color="primary.500" />
        </Link>
      ) : (
        <HStack
          as="a"
          onClick={() => openWindow(link)}
          justifyContent="space-between"
          py="6px"
          px="12px"
          w="100%"
        >
          <Text as="span" fontSize="lg" fontWeight="medium">
            {label}
          </Text>
          <ChevronRightIcon fontSize="2xl" color="primary.500" />
        </HStack>
      )}
    </>
  );
};

const Top: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, refetch }: HttpRequestType = useHttpRequest(getUser);

  useEffect(() => {
    if (!isLoading) {
      // クーポン獲得モーダル
      if (data?.is_unread_coupon_exist) onOpen();
    }
  }, [isLoading]);

  return (
    <MainLayout title="会員証">
      <VStack spacing="16px">
        <VStack
          spacing="0"
          filter="drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.05))"
          w="100%"
        >
          <Text
            borderRadius="8px 8px 0px 0px"
            bgColor="primary.500"
            textAlign="center"
            fontSize="xl"
            color="white"
            py="12px"
            w="100%"
          >
            会員証
          </Text>
          <Box
            borderRadius="0px 0px 8px 8px"
            bgColor="white"
            px="42px"
            py="16px"
            w="100%"
          >
            {data && !isLoading ? (
              <QrCode uid={data.uid} refetch={refetch} />
            ) : (
              <Loader />
            )}
          </Box>
        </VStack>
        <Accordion
          allowToggle
          filter="drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.05))"
          borderRadius="8px"
          py="12px"
          w="100%"
        >
          <AccordionItem border="none">
            <AccordionButton justifyContent="space-between" py={0}>
              <Text fontSize="lg" fontWeight="medium">
                スタンプを集める方法
              </Text>
              <AccordionIcon color="primary.500" fontSize="3xl" />
            </AccordionButton>
            <AccordionPanel pb={0}>
              <OrderedList lineHeight="26px" mb="8px">
                <ListItem>
                  会計時に上記の会員証コードをご提示ください。
                  <br />
                  <Text as="span" color="primary.500">
                    ※会計完了後の会員証の提示はお受けできませんので、会計の最初にご提示ください。
                  </Text>
                </ListItem>
                <ListItem>
                  会員証ご提示により、期間累計クーポン対象お支払い金額800円（税込）につきスタンプ1個獲得できます。
                  <br />
                  <Text as="span" color="primary.500">
                    ※クーポンを適用される場合は割引後の金額が対象となります。
                    <br />
                    ※毎年5月16日にスタンプおよび累計お支払い金額はリセットされます。
                  </Text>
                </ListItem>
                <ListItem>
                  スタンプの反映はご利用日から2日後以降となります。
                </ListItem>
                <ListItem>
                  スタンプが一定数貯まるとクーポンを発行いたします。
                  <br />
                  ※有効期限10日前にお知らせが届きます。
                </ListItem>
                <ListItem>
                  <Text as="span" color="primary.500">
                    すし銚子丸、すし銚子丸&nbsp;雅、江戸前すし&nbsp;百萬石の3業態が対象となります。
                  </Text>
                </ListItem>
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <RecommendedCoupon />
        <VStack
          filter="drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.05))"
          borderRadius="8px"
          bgColor="white"
          w="100%"
          py="6px"
          mt="16px"
        >
          <LinkBox
            link="https://www.choushimaru.co.jp/privacy/"
            label="プライバシーポリシー"
          />
          <LinkBox
            link="https://cdn.choushimaru.digiclueapp.dev/term/index.html"
            label="利用規約"
          />
          <LinkBox
            link="/contact"
            label="お問い合わせ案内"
            isRouterLink={true}
          />
        </VStack>
      </VStack>
      <CouponNoticeModal isOpen={isOpen} onClose={onClose} />
    </MainLayout>
  );
};
export default Top;
