import React, { useEffect, useState } from 'react';
import { Box, Grid, GridItem, Text, Image } from '@chakra-ui/react';

import tierConfig from './tiers';

import WhiteStamp from '../../../assets/img/stamp_wh.png';

interface StampListType {
  tier: 'bronze' | 'silver' | 'gold';
  totalStamps: number;
  currStamps: number;
  pageNum: number;
}

const StampList: React.FC<StampListType> = ({
  tier,
  totalStamps,
  currStamps,
  pageNum
}) => {
  const [stamps, setStamps] = useState<React.ReactElement[]>();

  // 会員レベルの設定
  const tierSettings = tierConfig.filter((config) => config.name === tier);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < tierSettings[0].stampPerPage; i++) {
      arr.push(
        <GridItem borderRadius="55px" w="100%" h="auto" key={`st-${i}`}>
          {currStamps > i ? (
            <Image src={tierSettings[0].stampimage} />
          ) : (
            <Image src={WhiteStamp} />
          )}
        </GridItem>
      );
    }
    setStamps(arr);
  }, []);

  return (
    <Box
      borderRadius="8px"
      bgGradient={tierSettings[0].bg}
      filter="drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.05))"
      w="100%"
      p="16px"
    >
      <Box mb="16px">
        {/* TODO: 期間日付を動的に変更 */}
        <Text color="dark.600">スタンプ集計期間：2024/5/16～2025/5/15</Text>
        <Text color="dark.600">獲得スタンプ数：{totalStamps}個</Text>
        <Text color="dark.600">獲得スタンプ台帳数：{pageNum}ページ</Text>
      </Box>
      <Grid templateColumns="repeat(5, 1fr)" gap={[3, 2, 3, 5]}>
        {stamps}
      </Grid>
    </Box>
  );
};
export default StampList;
