import React from 'react';
import { Box, Image } from '@chakra-ui/react';

import { ZINDEX } from 'utils/constants';

import Logo from '../assets/img/logo.png';

const Header: React.FC = () => {
  return (
    <Box
      w="100%"
      h="1px"
      mb="3.5rem"
      bg="dark.200"
      position="fixed"
      zIndex={ZINDEX.header}
      _before={{
        content: '""',
        h: '8rem',
        w: '70%',
        position: 'absolute',
        top: '-4rem',
        left: '50%',
        backgroundColor: 'white',
        transform: 'translate(-50%, -10%)',
        borderRadius: '75%',
        border: '1px solid #D9D9D9'
      }}
    >
      <Image
        src={Logo}
        h="2.8rem"
        position="absolute"
        top="30%"
        left="50%"
        py="4px"
        transform="translate(-50%, 0%)"
      />
    </Box>
  );
};
export default Header;
