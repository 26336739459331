import React from 'react';
import { VStack, Text } from '@chakra-ui/react';

import { NewsListResponseType } from 'types/NewsType';
import useHttpRequest from 'hooks/useHttpRequest';
import { getMyNews } from 'api';

import NewsCard from 'components/Card/NewsCard';
import Loader from 'components/Loader';

const MyStoreNews: React.FC = () => {
  const { data, isLoading } = useHttpRequest(getMyNews);

  return (
    <VStack spacing="12px">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!data.notifications.length && <Text>お知らせがありません。</Text>}
          {data.notifications.map((news: NewsListResponseType, ind: number) => (
            <NewsCard
              link={`/news/${news.id}`}
              title={news.title}
              date={news.date}
              store={news.stores_name}
              width="330px"
              key={`cp-${ind}`}
            />
          ))}
        </>
      )}
    </VStack>
  );
};
export default MyStoreNews;
