import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Box, HStack, Image, Text, StackProps } from '@chakra-ui/react';

import { ZINDEX } from 'utils/constants';

import StoreBadgeList from 'components/StoreBadgeList';

import SumiImage from '../../assets/img/sumi.png';
import LogoImage from '../../assets/img/logo.png';

interface CardCouponType extends StackProps {
  link: string;
  image: string;
  store: string[];
  title: string;
  date: string;
  isUsed: boolean;
}

const CouponCard: React.FC<CardCouponType> = ({
  link,
  image,
  store,
  title,
  date,
  isUsed,
  ...props
}) => {
  return (
    <RouteLink to={link} style={{ width: props.width as string }}>
      <HStack
        bgColor="white"
        borderRadius="4px"
        border="1px solid dark.200"
        alignItems="start"
        position="relative"
        p="12px"
        {...props}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          bgColor: 'dark.500',
          opacity: isUsed ? '0.3' : '0',
          borderRadius: '4px'
        }}
      >
        <Box position="relative">
          {isUsed && (
            <Image
              src={SumiImage}
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              m="auto"
              p="6px"
              zIndex={ZINDEX.sumiIcon}
              bgColor="#231917b3"
            />
          )}
          <Box display="flex" alignItems="center" w="72px" h="72px">
            <Image src={image} fallbackSrc={LogoImage} />
          </Box>
        </Box>
        <Box>
          <StoreBadgeList store={store} />
          <Text fontSize="lg" fontWeight="medium" wordBreak="break-all">
            {title}
          </Text>
          <Text color="dark.400">＜有効期限＞</Text>
          <Text color="dark.400">{date}</Text>
        </Box>
      </HStack>
    </RouteLink>
  );
};

export default CouponCard;
