// LINE
export const LIFF_ID = process.env.REACT_APP_LIFF_ID as string;

// GA
export const GA_TRACKING_ID = process.env.REACT_APP_GTM_ID;

// API
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// APP
export const ZINDEX = {
  header: 11,
  footer: 11,
  qrBlurButton: 1,
  sumiIcon: 1
};
