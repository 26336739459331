import React from 'react';
import { Wrap, WrapItem, Badge } from '@chakra-ui/react';

const StoreBadgeList: React.FC<{ store: string[] }> = ({ store }) => {
  // 全店共通の場合は青バッジ
  const getBadgeVariant = (store: string) =>
    store === '全店共通' ? 'secondary' : 'primary';

  return (
    <Wrap spacing="4px" mb="8px" flexWrap="wrap">
      {store.length <= 0 && (
        <WrapItem>
          <Badge
            whiteSpace="break-spaces"
            variant={getBadgeVariant('全店共通')}
          >
            全店共通
          </Badge>
        </WrapItem>
      )}
      {store.map((store, ind) => (
        <WrapItem key={`bd-${ind}`}>
          <Badge whiteSpace="break-spaces" variant={getBadgeVariant(store)}>
            {store}
          </Badge>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default StoreBadgeList;
