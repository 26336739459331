import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'style/theme';
import AppRoutes from 'routes';

const App: React.FC = () => (
  <ChakraProvider theme={theme}>
    <AppRoutes />
  </ChakraProvider>
);

export default App;
