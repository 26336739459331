import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Badge: ComponentStyleConfig = {
  variants: {
    primary: {
      bgColor: '#FAE6E8',
      color: 'primary.500',
      borderRadius: '5px',
      py: '4px',
      px: '8px'
    },
    secondary: {
      bgColor: '#0000FF',
      color: 'white',
      borderRadius: '5px',
      py: '4px',
      px: '8px'
    }
  }
};

export default Badge;
