import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

const Accordion: ComponentMultiStyleConfig = {
  parts: ['root', 'button'],
  baseStyle: {
    root: {
      bgColor: 'white'
    },
    button: {
      _hover: {
        boxShadow: 'none',
        bgColor: 'white'
      },
      _focus: {
        boxShadow: 'none',
        bgColor: 'white'
      },
      _active: {
        boxShadow: 'none',
        bgColor: 'white'
      }
    }
  }
};

export default Accordion;
