import React from 'react';
import { createRoot } from 'react-dom/client';
import liff from '@line/liff';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { getUser, handleRedirectToErrorPage, registerUser } from 'api';
import { ChakraProvider, theme } from '@chakra-ui/react';
import CommonError from 'pages/Error/CommonError';
import { API_RESPONSE_STATUS } from 'libs/constants';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const PAGES_PREVENT_REGISTER_USER = ['/error'];

const paramStr = window.location.search;
const searchParams = new URLSearchParams(paramStr);
const utmParam = {
  utm_source: searchParams.get('utm_source') || '',
  utm_campaign: searchParams.get('utm_campaign') || '',
  utm_content: searchParams.get('utm_content') || '',
  utm_medium: searchParams.get('utm_medium') || ''
};

const isNotErrorPage = !PAGES_PREVENT_REGISTER_USER.includes(
  window.location.pathname
);

// NOTE: await を使うため、closure にしないと怒られる
(async () => {
  // NOTE: Get users information and Create a user in case of 401 response from Get user API.
  const checkUserRegistration = async () => {
    const getUserRes = await getUser()
      .then((res) => res.data)
      .catch((error) => ({
        ...error?.response?.data,
        status: error?.response?.status
      }));

    if (getUserRes.status === API_RESPONSE_STATUS.SUCCEEDED)
      return 'registered';

    // ユーザー照会時に未登録エラー以外のエラーは処理終了
    if (!getUserRes.errors?.includes('登録されていないユーザです')) {
      return handleRedirectToErrorPage('');
    }

    const registerUserRes = await registerUser(utmParam)
      .then((res) => res.data)
      .catch((error) => error?.response?.data);

    if (registerUserRes.status === API_RESPONSE_STATUS.SUCCEEDED) {
      return setTimeout(() => {
        location.reload();
      }, 250);
    }

    // ユーザー登録失敗エラー
    handleRedirectToErrorPage('create-user-failure');
  };

  if (isNotErrorPage) {
    // liffの初期化
    await liff
      .init({
        liffId: process.env.REACT_APP_LIFF_ID || '',
        withLoginOnExternalBrowser: true
      })
      .catch(() => {
        localStorage.clear();
        handleRedirectToErrorPage('');
      });

    // ユーザー照会＆未登録の場合、登録を行う
    const res = await checkUserRegistration();
    if (res !== 'registered') return;

    return root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }

  // エラー画面への遷移
  root.render(
    <ChakraProvider theme={theme}>
      <CommonError />
    </ChakraProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
