import React, { useState } from 'react';
import { Box, Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';

import { openWindow } from 'utils/line';

import MainLayout from 'components/Layout/MainLayout';
import MyStore from './StoreMyStore';
import BrandSelect from './StoreBrandSelect';
import AreaSearch from './StoreAreaSearch';

import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

const Store: React.FC = () => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false); // マイ店舗を更新されているかの状態
  const [myStore, setMyStore] = useState<string[]>([]); // マイ店舗ID配列
  const [brand, setBrand] = useState<string>();

  return (
    <MainLayout title="店舗情報" position="relative" mb="28px">
      <VStack spacing="48px">
        <Box w="100%">
          <Text fontSize="lg" fontWeight="medium" mb="16px">
            マイ店舗
          </Text>
          <MyStore
            myStore={myStore}
            setMyStore={setMyStore}
            setIsUpdated={setIsUpdated}
            isUpdated={isUpdated}
          />
        </Box>
        <Box w="100%">
          <Text fontSize="lg" fontWeight="medium" mb="16px">
            ブランドから探す
          </Text>
          <BrandSelect setBrand={setBrand} />
        </Box>
        <Box w="100%">
          <Text fontSize="lg" fontWeight="medium" mb="16px">
            エリアから探す
          </Text>
          <AreaSearch
            brand={brand}
            myStore={myStore}
            setMyStore={setMyStore}
            setIsUpdated={setIsUpdated}
          />
        </Box>
      </VStack>
      <Button
        variant="primary-ghost"
        position="fixed"
        borderRadius="16px"
        fontSize="sm"
        fontWeight="medium"
        boxShadow="1px 3px 8px rgba(0, 0, 0, 0.05)"
        textAlign="end"
        bottom="98px"
        right="6%"
        py="10px"
        px="12px"
        onClick={() => openWindow('https://www.choushimaru.co.jp/shoplist/')}
      >
        <HStack>
          <Text>
            その他
            <br />
            店舗情報
          </Text>
          <Icon stroke="primary.500" w="1.2rem" h="1.2rem">
            <LinkIcon />
          </Icon>
        </HStack>
      </Button>
    </MainLayout>
  );
};
export default Store;
