import React from 'react';
import { Box, Center, Heading, Link, Text } from '@chakra-ui/react';
import FullHeightLayout from 'components/Layout/FullHeightLayout';
import { openWindow } from 'utils/line';

const Contact: React.FC = () => {
  const openContactForm = () => {
    openWindow('https://line.me/R/ti/p/@905olqjl');
  };

  return (
    <FullHeightLayout
      title="お問い合わせ案内"
      bgColor="white"
      color="#333333"
      mt="-24px"
    >
      <Box paddingTop="64px">
        <Heading as="h1" fontSize="16px">
          お問い合わせ案内について
        </Heading>
      </Box>
      <Box marginTop="10px" fontSize="14px" lineHeight={1.86}>
        <Text>
          ■ミニアプリお問い合わせ手順
          <br />
          ①「すし銚子丸ミニアプリ問い合わせアカウント」を友だち追加してください。
          <br />
          ②「すし銚子丸ミニアプリ問い合わせアカウント」内にあるメニュー「お問い合わせフォーム」より必要項目をご記入の上、送信してください。
          <br />
          ③「すし銚子丸ミニアプリ問い合わせアカウント」内の1対1のトークにて、ご返信させていただきます。
        </Text>
      </Box>
      <Box marginTop="26px" lineHeight={1.86}>
        <Text>
          【ご注意】
          <br />
          ※本問い合わせフォームは「銚子丸LINE会員証アプリ」専用となります。
          <br />
          ※アプリに関するご意見ご質問などございましたら、上記の窓口にてご投稿ください。
          <br />
          ※ご返答まで数日かかります。また、お客様よりお寄せいただいたご意見メールすべてにご回答いたしかねる場合もございますので、あらかじめご了承ください。
        </Text>
      </Box>
      <Box marginTop="26px" lineHeight={1.86}>
        <Text>
          【よくあるお問い合わせ】
          <br />
          ・スタンプ反映のタイミングについて
        </Text>
        <Text as="u">
          反映はご利用日から2日後以降となります。即時反映とはなりませんので何卒ご了承ください。
        </Text>
      </Box>
      <Center>
        <Link
          backgroundColor="primary.500"
          borderRadius="5px"
          color="white"
          fontSize="16px"
          fontWeight="medium"
          lineHeight={1.75}
          mt="64px"
          mx="auto"
          px="14px"
          py="12px"
          _hover={{ textDecoration: 'none' }}
          onClick={openContactForm}
        >
          ミニアプリ問い合わせアカウント
        </Link>
      </Center>
    </FullHeightLayout>
  );
};
export default Contact;
