import React from 'react';
import { Box, Text, HStack, Button, Icon, Link } from '@chakra-ui/react';

import { openWindow } from 'utils/line';

import { ReactComponent as TelIcon } from '../../assets/icons/tel.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

interface StoreCardType {
  id: number;
  name: string;
  address: string;
  tel: string;
  link: string;
  mapLink?: string;
  isFavorite: boolean;
  handleDelete: () => void;
  handleAdd?: (storeId: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StoreCard: React.FC<StoreCardType> = ({
  id,
  name,
  address,
  tel,
  link,
  mapLink,
  isFavorite,
  handleDelete,
  handleAdd
}) => {
  return (
    <Box
      data-store-id={id}
      bgColor="white"
      borderRadius="4px"
      border="1px solid #D9D9D9"
      opacity={1}
      transition="ease-out .6s"
      p="16px"
      w="100%"
    >
      <Text fontSize="lg" fontWeight="medium" mb="8px">
        {name}
      </Text>
      <Text
        as="a"
        onClick={() => mapLink && openWindow(mapLink)}
        color="dark.400"
      >
        〒{address}
      </Text>
      <HStack mt="4px" mb="16px">
        <TelIcon />
        <Link textDecor="underline" color="blue.500" href={`tel:${tel}`}>
          {tel}
        </Link>
      </HStack>
      <HStack w="100%">
        {isFavorite ? (
          <Button variant="secondary-outline" w="100%" onClick={handleDelete}>
            マイ店舗削除
          </Button>
        ) : (
          <Button
            variant="primary-solid"
            w="100%"
            onClick={(e) => {
              handleAdd && handleAdd(id.toString(), e);
            }}
          >
            マイ店舗登録
          </Button>
        )}
        <Button
          variant={isFavorite ? 'primary-outline' : 'primary-ghost'}
          onClick={() => openWindow(link)}
          w="100%"
        >
          <Text pe="4px">詳細を見る</Text>
          <Icon stroke="primary.500" w="1.2rem" h="1.2rem">
            <LinkIcon />
          </Icon>
        </Button>
      </HStack>
    </Box>
  );
};

export default StoreCard;
