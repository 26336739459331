import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Box, HStack, Text } from '@chakra-ui/react';

import StoreBadgeList from 'components/StoreBadgeList';

interface NewsCardType {
  link: string;
  store: string[];
  title: string;
  date: string;
  width?: string;
}

const NewsCard: React.FC<NewsCardType> = ({
  link,
  store,
  title,
  date,
  width
}) => {
  return (
    <RouteLink to={link} style={{ width: '100%' }}>
      <HStack
        bgColor="white"
        borderRadius="4px"
        border="1px solid dark.200"
        alignItems="start"
        minW={width ?? '100%'}
        position="relative"
        p="12px"
      >
        <Box>
          <StoreBadgeList store={store} />
          <Text fontWeight="medium" my="8px" wordBreak="break-all">
            {title}
          </Text>
          <Text fontSize="sm" color="dark.400">
            {date}
          </Text>
        </Box>
      </HStack>
    </RouteLink>
  );
};

export default NewsCard;
