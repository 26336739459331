import React, { useEffect, useState } from 'react';
import { VStack, useDisclosure, Text, useToast } from '@chakra-ui/react';

import { MyStoreListType } from 'types/StoreType';
import useHttpRequest from 'hooks/useHttpRequest';
import { deleteMyStore, getMyStoreList } from 'api';

import StoreCard from 'components/Card/StoreCard';
import ConfirmModal from './StoreDeleteModal';
import Loader from 'components/Loader';

interface MyStoreType {
  isUpdated: boolean;
  myStore: string[];
  setMyStore: React.Dispatch<React.SetStateAction<string[]>>;
  setIsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

type HttpRequestType = Omit<ReturnType<typeof useHttpRequest>, 'data'> & {
  data?: MyStoreListType;
};

const MyStore: React.FC<MyStoreType> = ({
  isUpdated,
  myStore,
  setMyStore,
  setIsUpdated
}) => {
  const [selectedStore, setSelectedStore] = useState<string>();
  const [deleteStore, setDeleteStore] = useState<string>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, refetch }: HttpRequestType =
    useHttpRequest(getMyStoreList);
  const toast = useToast();

  const handleRemove = () => {
    if (deleteStore) {
      deleteMyStore(deleteStore)
        .then(() => {
          const targetEl = document.querySelector(
            `[data-store-id="${deleteStore}"]`
          ) as HTMLDivElement;
          targetEl.style.opacity = '0';

          const newMystores = myStore.filter((id) => id !== deleteStore);
          setMyStore(newMystores);
          setIsUpdated(true);
        })
        .catch((err) => {
          // 失敗
          toast({
            title: err.response.data.errors[0],
            position: 'top',
            status: 'error',
            isClosable: true
          });
        });
    }

    onClose();
  };

  useEffect(() => {
    if (!isLoading) {
      const mystores =
        data?.my_stores.map((store) => store.id.toString()) ?? [];
      setMyStore(mystores);
      setIsUpdated(false);
    }
  }, [isLoading]);

  // エリア検索から更新があった場合、refetch
  useEffect(() => refetch(), [isUpdated]);

  return (
    <VStack spacing="16px">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!!data?.my_stores && data.my_stores.length > 0 ? (
            data.my_stores.map((store, ind) => (
              <StoreCard
                id={store.id}
                name={store.name}
                address={store.postal_code + ' ' + store.address}
                tel={store.tel}
                link={store.url}
                mapLink={store.google_map_url}
                isFavorite={true}
                handleDelete={() => {
                  setSelectedStore(store.name);
                  setDeleteStore(store.id.toString());
                  onOpen();
                }}
                key={`st-${ind}`}
              />
            ))
          ) : (
            <Text>マイ店舗が登録されていません。</Text>
          )}
        </>
      )}
      <ConfirmModal
        title={selectedStore || ''}
        isOpen={isOpen}
        onClose={onClose}
        handleRemove={handleRemove}
      />
    </VStack>
  );
};
export default MyStore;
