import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

const Table: ComponentMultiStyleConfig = {
  parts: ['table', 'thead', 'th', 'tr', 'th'],
  variants: {
    custom: {
      table: {
        border: '1px solid #D9D9D9'
      },
      thead: {
        borderBottom: '1px solid #D9D9D9'
      },
      th: {
        bgColor: 'dark.100',
        wordBreak: 'keep-all',
        whiteSpace: 'normal',
        p: '8px',
        _notLast: {
          borderRight: '1px solid #D9D9D9'
        }
      },
      tr: {
        borderBottom: '1px solid #D9D9D9'
      },
      td: {
        wordBreak: 'keep-all',
        whiteSpace: 'normal',
        _notLast: {
          borderRight: '1px solid #D9D9D9'
        },
        _first: {
          bgColor: 'dark.100',
          textAlign: 'center'
        }
      }
    }
  }
};

export default Table;
