import React from 'react';
import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  HStack,
  Button
} from '@chakra-ui/react';

interface ConfirmModalType {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  handleRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ConfirmModal: React.FC<ConfirmModalType> = ({
  title,
  isOpen,
  onClose,
  handleRemove
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="92%">
        <ModalBody py="20px">
          <Text fontSize="lg" fontWeight="medium" mb="16px">
            {title}
          </Text>
          <Text>選択した店舗をマイ店舗削除しますか？</Text>
          <HStack textAlign="center" w="100%" mt="24px" px="24px">
            <Button variant="link" fontSize="lg" w="100%" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              variant="link"
              fontSize="lg"
              color="primary.500"
              w="100%"
              onClick={handleRemove}
            >
              削除
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default ConfirmModal;
