import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Button
} from '@chakra-ui/react';

import { readCoupon } from 'api';

interface CouponNoticeModalType {
  isOpen: boolean;
  onClose: () => void;
}

const CouponNoticeModal: React.FC<CouponNoticeModalType> = ({
  isOpen,
  onClose
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    readCoupon().then(() => onClose());
  };

  const handleGoToCoupon = () => {
    readCoupon().then(() => navigate('/coupon'));
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="80%">
        <ModalCloseButton
          position="relative"
          left={0}
          top={0}
          mt="8px"
          ms="8px"
        />
        <ModalBody pb="20px" pt="12px">
          <Text textAlign="center" fontSize="lg" fontWeight="medium">
            クーポンを獲得しました。
          </Text>
          <Button
            variant="primary-solid"
            fontSize="lg"
            minH="50px"
            w="100%"
            mt="20px"
            onClick={handleGoToCoupon}
          >
            クーポンを確認する
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default CouponNoticeModal;
