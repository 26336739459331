import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, Text, Icon, Flex } from '@chakra-ui/react';

import links from './links';

const FooterItem: React.FC<(typeof links)[number] & { isActive: boolean }> = ({
  label,
  link,
  icon,
  isActive
}) => {
  // アイコンをアクティブ化にする
  const iconStroke = isActive ? 'primary.500' : 'dark.300';
  const iconFill = isActive ? 'primary.100' : 'none';

  // 会員証の場合はアイコンのデザインが変わる
  const iconType =
    label !== '会員証' ? (
      <Icon w="100%" h="1.5rem" stroke={iconStroke} fill={iconFill} mb="6px">
        {icon}
      </Icon>
    ) : (
      <Flex
        position="absolute"
        bgColor="primary.500"
        borderRadius="75px"
        boxShadow="0px 0px 10px rgba(193, 39, 45, 0.3)"
        top="-20px"
        p="8px"
        w="60px"
        h="60px"
      >
        <Icon stroke="white" w="100%" h="100%">
          {icon}
        </Icon>
      </Flex>
    );

  return (
    <RouterLink to={link} style={{ flex: '1 1 0' }}>
      <ListItem
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        p="12px"
        h="100%"
      >
        {iconType}
        <Flex h="100%" alignItems="flex-end">
          <Text fontSize="sm" mb="6px">
            {label}
          </Text>
        </Flex>
      </ListItem>
    </RouterLink>
  );
};

export default FooterItem;
