import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const StringToJsx: React.FC<
  { text?: string; className?: string } & TextProps
> = (props) => {
  const { text, className } = props;
  const texts = (text ?? '').split(/(?:\r\n|\r|\n)/g);

  return (
    <>
      {texts.map((text, ind) => (
        <Text
          display="block"
          className={className}
          key={`txt-${ind}`}
          {...props}
        >
          {text || '　'}
        </Text>
      ))}
    </>
  );
};
export default StringToJsx;
