import React from 'react';
import { Flex, Container, Box, ContainerProps } from '@chakra-ui/react';

import usePageTitle from 'hooks/usePageTitle';

import Header from '../Header';
import Footer from '../Footer';

interface MainLayoutType extends ContainerProps {
  title?: string;
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutType> = ({
  title,
  children,
  ...props
}) => {
  usePageTitle(title || '銚子丸');

  return (
    <Flex direction="column" justifyContent="space-between" minHeight="100vh">
      <Box mb="1.8rem">
        <Header />
        <Container width={['100%', 480]} pb="5rem" pt="4.5rem" {...props}>
          {children}
        </Container>
      </Box>
      <Footer />
    </Flex>
  );
};

export default MainLayout;
