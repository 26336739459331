import React from 'react';
import { Button, Text } from '@chakra-ui/react';

interface ButtonAvailType {
  isDisabled: boolean;
  remaining: number;
  handleAvail: () => void;
}

interface ButtonActivateType {
  isUsed: boolean;
  handleActivate?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ButtonOpenQrType {
  onOpen: () => void;
}

/**
 * クーポンを選択するボタン
 */
export const ButtonAvail: React.FC<ButtonAvailType> = ({
  isDisabled,
  remaining,
  handleAvail
}) => {
  const buttonText = isDisabled
    ? '利用期間外'
    : `このクーポンを選択する（残り${remaining}回）`;

  return (
    <Button
      variant="primary-solid"
      py="12px"
      my="24px"
      h="48px"
      w="100%"
      isDisabled={isDisabled}
      onClick={handleAvail}
    >
      {buttonText}
    </Button>
  );
};

/**
 * クーポンを利用するボタン
 */
export const ButtonActivate: React.FC<ButtonActivateType> = ({
  isUsed,
  handleActivate
}) => {
  const buttonText = isUsed
    ? 'クーポン利用済み'
    : 'この画面を店舗のスタッフに提示してください';

  return (
    <>
      <Button
        variant="primary-ghost"
        py="12px"
        mt="24px"
        mb="4px"
        h="48px"
        w="100%"
        isDisabled={isUsed}
        onClick={handleActivate}
        loadingText="処理中..."
        spinnerPlacement="start"
      >
        {buttonText}
      </Button>
      <Text textAlign="center" fontWeight="medium" mb="32px">
        画面提示後に上記のボタンを押してください。
      </Text>
    </>
  );
};

/**
 * 会員証コードを開くボタン
 */
export const ButtonOpenQr: React.FC<ButtonOpenQrType> = ({ onOpen }) => {
  return (
    <>
      <Button
        variant="primary-outline"
        py="12px"
        mb="4px"
        h="48px"
        w="100%"
        onClick={onOpen}
      >
        会員証
      </Button>
      <Text fontWeight="medium" mb="24px">
        会員証とクーポンの提示タイミングは順不同で問題ございません。
      </Text>
    </>
  );
};
