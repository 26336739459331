import liff from '@line/liff';
import axios, { AxiosHeaders } from 'axios';
import { BASE_URL } from '../utils/constants';

export type RegisterUTMAccountType = {
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
};

export type ErrorCodeType =
  | 'server-error'
  | 'session-expired'
  | 'create-user-failure'
  | '';

export const handleRedirectToErrorPage = (errorCode: ErrorCodeType) =>
  window.location.replace(`/error?error_code=${errorCode}`);

export const getRequestHeader = (hasAccessToken = false) => {
  const headers = new AxiosHeaders({
    'Content-Type': 'application/json; charset=UTF-8'
  });

  if (hasAccessToken) {
    const accessToken = liff.getAccessToken();
    if (!accessToken) {
      localStorage.clear();
      handleRedirectToErrorPage('session-expired');
      return;
    }
    headers['Access-Token'] = accessToken;
  }

  const idToken = liff.getIDToken();
  if (!idToken) {
    localStorage.clear();
    handleRedirectToErrorPage('session-expired');
    return;
  }
  headers['Token-Id'] = idToken;

  return headers;
};

export const http = axios.create({
  baseURL: BASE_URL
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    if (error.response) {
      const errorObject = error.response.data?.errors;
      // NOTE: Expired token but authorized/registered user
      if (errorObject.includes('IdToken expired.')) {
        localStorage.clear();
        handleRedirectToErrorPage('session-expired');
        return;
      }

      // NOTE: Server error for any apis.
      if (error.response.status === 500) {
        handleRedirectToErrorPage('server-error');
        return;
      }
    }
    return Promise.reject(error);
  }
);

export const registerUser = (data?: RegisterUTMAccountType) =>
  http.request({
    url: '/users',
    method: 'POST',
    data: data,
    headers: getRequestHeader()
  });

export const getUser = (hasAccessToken?: boolean) =>
  http.request({
    url: '/users/current',
    method: 'GET',
    headers: getRequestHeader(hasAccessToken)
  });

export const getNews = () =>
  http.request({
    url: '/notifications',
    method: 'GET',
    headers: getRequestHeader()
  });

export const getMyNews = () =>
  http.request({
    url: '/users/current/my_stores/notifications',
    method: 'GET',
    headers: getRequestHeader()
  });

export const getNewsDetail = (id: string) =>
  http.request({
    url: `/notifications/${id}`,
    method: 'GET',
    headers: getRequestHeader()
  });

export const getCouponList = () =>
  http.request({
    url: '/users/current/coupons',
    method: 'GET',
    headers: getRequestHeader()
  });

export const getCouponDetails = (id: string) =>
  http.request({
    url: `/users/current/coupons/${id}`,
    method: 'GET',
    headers: getRequestHeader()
  });

export const getCouponInfo = () =>
  http.request({
    url: '/coupons',
    method: 'GET',
    headers: getRequestHeader()
  });

export const readCoupon = () =>
  http.request({
    url: '/users/current/coupons/read',
    method: 'PATCH',
    headers: getRequestHeader()
  });

export const applyCoupon = (data: { coupon_id: number }) =>
  http.request({
    url: '/users/current/coupons/',
    method: 'POST',
    data: data,
    headers: getRequestHeader()
  });

export const getAllStoreList = (brandId?: string) =>
  http.request({
    url: '/stores',
    method: 'GET',
    params: {
      brand_id: brandId
    },
    headers: getRequestHeader()
  });

export const getBrandList = () =>
  http.request({
    url: '/brands',
    method: 'GET',
    headers: getRequestHeader()
  });

export const getMyStoreList = () =>
  http.request({
    url: '/users/current/my_stores',
    method: 'GET',
    headers: getRequestHeader()
  });

export const addMyStore = (data: { store_id: number }) =>
  http.request({
    url: '/users/current/my_stores',
    method: 'POST',
    data: data,
    headers: getRequestHeader()
  });

export const deleteMyStore = (id: string) =>
  http.request({
    url: `/users/current/my_stores/${id}`,
    method: 'DELETE',
    headers: getRequestHeader()
  });
